class Storage {

  constructor(props) {
    this.props = props || {}
    this.source = this.props.source || window.localStorage
    this.initRun();
  }

  initRun() {
    /*
        * set 存储方法
        * @ param {String} 	key 键
        * @ param {String} 	value 值，存储的值可能是数组/对象，不能直接存储，需要转换 JSON.stringify
        * @ param {String} 	expired 过期时间，以秒为单位
        */
    const reg = new RegExp("__expires__");
    let data = this.source;
    let list = Object.keys(data);
    if (list.length > 0) {
      list.map((key, v) => {
        if (!reg.test(key)) {
          let now = Date.now();
          let expires = data[`${key}__expires__`] || Date.now + 1;
          if (now >= expires) {
            this.remove(key);
          }
          ;
        }
        ;
        return key;
      });
    }

  }

  /*
      * set 存储方法
      * @ param {String} 	key 键
      * @ param {String} 	value 值，
      * @ param {String} 	expired 过期时间，以秒为单位，非必须
      */
  set(key, value, expired) {
    let source = this.source;
    source[key] = JSON.stringify(value);
    if (expired) {
      source[`${key}__expires__`] = Date.now() + 1000 * expired
    }
    return value;
  }

  /*
      * get 获取方法
      * @ param {String} 	key 键
      * @ param {String} 	expired 存储时为非必须字段，所以有可能取不到，默认为 Date.now+1
      * @ 由@IT·平头哥联盟-首席填坑官∙苏南 分享
      */
  get(key) {
    const source = this.source,
      expired = source[`${key}__expires__`] || Date.now + 1;
    const now = Date.now();

    if (now >= expired) {
      this.remove(key);
      return;
    }
    var value
    try{
      value = source[key] ? JSON.parse(source[key]) : source[key];
    }catch(err){
      value = source[key]
    }
    return value;
  }

  /**
   * 移除
   * @param key
   * @returns {*}
   */
  remove(key) {
    const data = this.source,
      value = data[key];
    delete data[key];
    delete data[`${key}__expires__`];
    return value;
  }
}

export default Storage;



