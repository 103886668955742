<template>
  <div class="copyCom" id="copyCom">
      <span>佛山斗工科技有限公司出品</span>
      <span>版权所有违者必究</span>
      <img v-if="taskData.qrcode" :src="taskData.qrcode" alt="">
  </div>
</template>
<script>
export default {
  props: ["taskData"],
  data() {
    return {
    };
  },
  mounted(){
  },
  methods: {}
};
</script>
<style lang="stylus" scoped>
.copyCom{
    width: 100%;
    display: flex;
    justify-content:space-between;
    height 100px
    align-items: flex-end
    margin-bottom 150px
    position relative
    img{
      position absolute
      right 30%
      bottom 0
      width 100px
      height auto
      margin-bottom -30px
    }
}
</style>