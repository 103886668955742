import service from './request'

class Fetch {
  fetch(url, args = {}) {
    return new Promise((resolve, reject) => {
      service.request({
        url,
        ...args
      }).then(res => {
        resolve(res)
      })
    })
  }
}

const axs = new Fetch()

export default axs
