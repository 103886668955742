var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dashboard-container"},[_c('header',{staticClass:"header-nav-container"},[_c('div',{staticClass:"header-nav-box flexed"},[_c('div',{staticClass:"header-logo",on:{"click":_vm.returnHome}},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/images/logo.png")}})]),(_vm.userData.maxRoleType == 'ADMINISTRATOR')?[_c('Menu',{staticClass:"main-top",attrs:{"mode":"horizontal","active-name":_vm.$route.name},on:{"on-select":_vm.handleSelect}},[_c('Submenu',{staticStyle:{"float":"right","max-height":"59px"},attrs:{"name":""}},[_c('template',{slot:"title"},[_c('label',{staticStyle:{"cursor":"pointer"}},[_c('Icon',{staticStyle:{"font-size":"28px"},attrs:{"type":"md-contact"}}),_vm._v(" "+_vm._s(_vm.userName)+" ")],1)]),_c('MenuItem',{attrs:{"name":"resetPassword"}},[_vm._v("重置密码")]),_c('MenuItem',{attrs:{"name":"logout"},on:{"click":_vm.logout}},[_vm._v("退出登录")])],2)],1)]:[(
              this.$route.name == 'myProject' ||
              this.$route.name == 'myConsole'
            )?_c('div',{staticClass:"corp-box"},[_vm._v(" "+_vm._s(_vm.userData.vname)+" 当前所在企业： "),_c('Dropdown',{staticStyle:{"cursor":"pointer","padding":"14px 0"},attrs:{"trigger":"click"}},[_c('div',{staticStyle:{"color":"#2db7f5"}},[_vm._v(" "+_vm._s(_vm.currentCorpName)+" "),_c('Icon',{attrs:{"type":"ios-arrow-down"}})],1),_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},_vm._l((_vm.corpList),function(item,index){return _c('DropdownItem',{key:index,nativeOn:{"click":function($event){return _vm.changeCorp(item)}}},[_vm._v(" "+_vm._s(item.corpName)+" ")])}),1)],1)],1):_vm._e(),_c('Menu',{staticClass:"main-top",attrs:{"mode":"horizontal","active-name":_vm.$route.name},on:{"on-select":_vm.handleSelect}},[_c('Submenu',{staticStyle:{"float":"right","max-height":"59px"},attrs:{"name":""}},[_c('template',{slot:"title"},[_c('label',{staticStyle:{"cursor":"pointer"}},[_c('Icon',{staticStyle:{"font-size":"28px"},attrs:{"type":"md-contact"}}),_vm._v(" "+_vm._s(_vm.userName)+" ")],1)]),_c('MenuItem',{attrs:{"name":"resetPassword"}},[_vm._v("重置密码")]),_c('MenuItem',{attrs:{"name":"logout"},on:{"click":_vm.logout}},[_vm._v("退出登录")])],2),(this.$route.name == 'newConsole' ? false : true)?_c('MenuItem',{staticStyle:{"float":"right","margin-right":"-20px"},attrs:{"name":""}},[_vm._v(" |   ")]):_vm._e(),(this.$route.name == 'newConsole' ? false : true)?_c('MenuItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHeader),expression:"showHeader"}],attrs:{"name":"myConsole"}},[_vm._v("控制台")]):_vm._e(),(this.$route.name == 'newConsole' ? false : true)?_c('MenuItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHeader),expression:"showHeader"}],attrs:{"name":"myProject"}},[_vm._v("我的项目")]):_vm._e()],1)]],2)]),_c('section',{staticClass:"ds-main-container"},[_c('div',{staticClass:"main-box",style:({
          width:
            this.$route.name == 'myProject' ||
            this.$route.name == 'myConsole' ||
            this.$route.name == 'overView' ||
            this.$route.name == 'newConsole'
              ? '100%'
              : '90%',
        })},[_c('transition',{attrs:{"name":"fade-transform","mode":"out-in"}},[(_vm.isRouterAlive)?_c('router-view',{key:_vm.key}):_vm._e()],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }