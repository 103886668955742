import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  corpList: ['1'],
  currentUserData: {}
}
const getters = {
  getCorpList(state){
    return state.corpList
  },
  getCurrentUserData(state){
    return state.currentUserData
  }
}
const mutations = {
  setCorpList(state, data) {
    state.corpList = data
  },
  setCurrentUserData(state,data){
    state.currentUserData = data
  }
}

export default new Vuex.Store({
  state,
  getters,
  mutations
});