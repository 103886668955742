import Vue from "vue";
import Storage from "./localStorage"
import constants from "../constants/constants"
import axs from "./fetch"
// import service from './request'
import router from '@router'
import { Message } from 'view-design'

let common = {};
const storage = new Storage();
const CONSTANTS = constants;
const $fetch = axs.fetch;
// const Message = Message;

/**
 * 设置认证
 * @param data
 */
common.setAuth = function (data) {
  data.rid = data.maxRoleId
  if (data.maxRoleType === "CORP") {
    data.dg_key = 1
  } else if (data.maxRoleType === "SUB_CORP") {
    data.dg_key = 2
  } else if (data.maxRoleType === "PROJECT") {
    data.dg_key = 3
  } else if (data.maxRoleType === "TEAM") {
    data.dg_key = 4
  }
  data.sso_phone = data.phoneNumber
  data.icorpid = data.corpId
  data.login_id = data.phoneNumber
  data.vname = data.username
  data.vcorp_name = data.corpName
  // data.richForceProjectSelectSwitch = data.richForceProjectSelectSwitch
  storage.set(CONSTANTS.EOVA_AUTH, data);
};
/**
 * 获取认证
 * @returns {string|any}
 */
common.getAuth = function () {
  let token = storage.get(CONSTANTS.EOVA_AUTH);
  if (!token) {
    // Message.error("登录已过期,请重新登录");
    // Message.info('This is a info tip');
    // Message.error("登录已过期,请重新登录");
    router.push({
      url: '/login'
    })
    return;
  }
  return token;
};
/**
 * 移除认证
 */
common.removeAuth = function () {
  storage.remove(CONSTANTS.EOVA_AUTH);
  storage.remove(CONSTANTS.USER_INFO);
};
/**
 * 获取页面缓存
 * @param key
 * @returns {string|any}
 */
common.getStorage = function (key) {
  return storage.get(key);
};
/**
 * 设置缓存
 * @param key
 * @param val
 * @param expire  有效时间 分钟 单位
 */
common.setStorage = function (key, val, expire) {
  storage.set(key, val, expire);
};
/**
 * 删除缓存
 * @param key
 */
common.delStorage = function (key) {
  storage.remove(key);
};
/**
 * 同步获取用户信息
 * @returns {Promise<string>}
 */
// common.getUserInfoSync = async function () {
//   let data = "";
//   let res = await $fetch('/rlapi/common/getUserInfo')
//   if (res) {
//     let userData = res.data;
//     storage.set(CONSTANTS.USER_INFO, userData);
//     data = userData
//     return data
//   }
// };
common.getUserInfoSync = function (res) {
  // let data = "";
  // let res = await $fetch('/rlapi/common/getUserInfo')
  if (res) {
    let userData = res.data.resultData;
    storage.set(CONSTANTS.USER_INFO, userData);
    // data = userData
    return userData
  }
};
/**
 * 时间格式化 年月日
 * @param date
 * @returns {*}
 */
common.formatDateCN = function (date) {
  return date.replace("年", "-").replace("月", "-").replace("日", "");
};
/**
 * 时间格式化
 * @param date
 * @returns {string}
 */
common.formatDate = function (date) {
  if (date instanceof Date) {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
  } else {
    return date
  }
};
/*
 * JSON数组去重
 * @param: [array] json Array
 * @param: [string] 唯一的key名，根据此键名进行去重
 */
common.uniqueArray = function (array, key) {
  var result = [array[0]];
  for (var i = 1; i < array.length; i++) {
    var item = array[i];
    var repeat = false;
    for (var j = 0; j < result.length; j++) {
      if (item[key] == result[j][key]) {
        repeat = true;
        break;
      }
    }
    if (!repeat) {
      result.push(item);
    }
  }
  return result;
};

/*
 * JSON数组判断
 * @param: [array] json Array
 * @param: [string] 唯一的key名，根据此键名进行去重
 */
common.judgeUniqueJson = function (array, key, value) {
  var res = false;
  for (var i = 0; i < array.length; i++) {
    var item = array[i];
    if (item[key] == value) {
      res = true;
      break;
    }
  }
  return res;
};
/**
 * 同步获取商品信息
 * @returns {Promise<Array>}
 */
common.getGoods = async function () {
  let res = await $fetch("/api/api/buyer/goods/list", {
    method: 'post'
  })
  if (res) {
    return res.resData;
  }
};
/**
 * 获取最新订单
 */
common.getNewestOrder = async function () {
  // let token = this.getAuth();
  this.user = this.getStorage(CONSTANTS.USER_INFO);
  let myBillForm = {};
  let res = await $fetch("/api/api/buyer/order/getNewest", {
    method: 'post',
    data: {
      'creator_id': this.user.id
    }
  })
  if (res) {
    Message.success("查询成功")
    console.log('myBillForm', res.resData)
    myBillForm = res.resData;
    return myBillForm;
  }
};

/**
 * 检查手机号
 * @param e
 * @returns {boolean|number}
 */
common.phoneCheck = function (e) {
  let msg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
  if (!msg.test(e.target.value)) {
    return false;
  } else {
    return Number(e.target.value);
  }
};

/**
 * 用户json转数组
 * @param json
 * @returns {Array}
 */
common.userStr = function (json) {
  let userStr = json.id + "_" + json.vname + "_" + json.login_id + "_" + json.icorpid + "_" + json.vcorp_name;
  return userStr;
};

/**
 * 跳转到协议页
 * @param type
 */
common.toProtocolPage = function (type) {
  switch (type) {
    case 1:
      window.open("http://v.dougongapp.com/registerAgreement.html", '_blank');
      // window.open("http://134.175.132.191:8080/userAgreement.html", '_blank');
      break;
    case 2:
      window.open("http://v.dougongapp.com/userPrivacy.html", '_blank');
      break;
    case 3:
      window.open("http://v.dougongapp.com/userComplaint.html", '_blank');
      break;
    default:
      window.location.href = "/#/feedback";
  }
};

/**
 * 判断是否移动端
 * @returns {RegExpMatchArray}
 */
common.isMobile = function () {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag;
};

/**
 * 重复提交按钮限制
 */
common.repeatBtn = function (method, duration) {
  common.setStorage(method, method, duration);
};

/**
 * 下载自拼链接文件
 */
common.openUrl = function (port, api, apiUrl) {
  var urlPath = window.document.location.href;
  // urlPath = urlPath.replace("https", "http");
  var docPath = window.document.location.pathname;
  var index = urlPath.indexOf(docPath);
  var cip = urlPath
    .substring(0, index)
    .replace("8082", port)
    .replace("9988", port);
  if (cip.indexOf(port) != -1) {
    api = 'api';
  }
  if (cip.indexOf("localhost") != -1) {
    cip = cip.replace("localhost", "134.175.132.191");
  }
  let fileUrl = ''
  fileUrl = `${cip}/${api}${apiUrl}`;
  return fileUrl
};

/**
 * 判断get方法url传参，自动拼接
 */
common.getUrl = function (url, data) {
  let getParam = function (data) {
    let url = "";
    for (var k in data) {
      if (data[k] || data[k] === 0 || data[k] === '0') {
        let value = data[k] !== undefined ? data[k] : "";
        url += `&${k}=${encodeURIComponent(value)}`;
      }
    }
    return url ? url.substring(1) : "";
  };
  let que = getParam(data);
  if (que) {
    url += (url.indexOf("?") < 0 ? "?" : "") + que;
  }
  return url;
};

export default common
