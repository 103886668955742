import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // 默认进入登录
    {
      path: '/redirect',
      hidden: true,
      children: [
        {
          path: '/redirect/:path*',
          component: () => import('@/views/redirect/index')
        }
      ]
    },
    {
      path: '/',
      redirect: '/home',//临时注释  方面进去superAdmin页面进行代码修改
      // redirect: '/superAdmin',//临时解开  方面进去superAdmin页面进行代码修改

    },
    // {
    //   path: '/',
    //   redirect: '/home',
    // },

    // 登录
    {
      path: '/login',
      name: 'login',
      meta: {
        keepAlive: false
      },
      component: () => import('../views/login/login.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        keepAlive: false
      },
      component: () => import('../views/login/logout.vue')
    },
    
    {
      path: '/home',
      name: 'home',
      meta: {
        keepAlive: false
      },
      component: () => import('../views/home/home.vue')
    },
    // 企业注册
    {
      path: '/registerEnterprise',
      name: 'registerEnterprise',
      meta: {
        keepAlive: false
      },
      component: () => import('../views/registerEnterprise/registerEnterprise.vue')
    },
    // 注册
    {
      path: '/register',
      name: 'register',
      meta: {
        keepAlive: false
      },
      component: () => import('../views/register/register.vue')
    },
    // 忘记密码
    {
      path: '/forgetPassword',
      name: 'forgetPassword',
      meta: {
        keepAlive: false
      },
      component: () => import('../views/forgetPassword/forgetPassword.vue')
    },
    // 重置密码
    {
      path: '/resetPassword',
      name: 'resetPassword',
      meta: {
        keepAlive: false
      },
      component: () => import('../views/resetPassword/resetPassword.vue')
    },
    // 我的项目
    {
      path: '/myProject',
      name: 'myProject',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/testTable/index.vue')
    },
    // 控制台
    {
      path: '/controlBooth',
      name: 'controlBooth',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/controlBooth/controlBooth.vue')
    },
    // 我的账单
    {
      path: '/myOrder',
      name: 'myOrder',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/myOrder/index.vue')
    },
    // 发票填写
    {
      path: '/invoice',
      name: 'invoice',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/myOrder/invoice.vue')
    },
    // 发票填写
    {
      path: '/invoice-success',
      name: 'invoiceSuccess',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/myOrder/invoice-success.vue')
    },
    // 我的订单
    {
      path: '/orderList',
      name: 'orderList',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/myOrder/order-list.vue')
    },
    // 项目统计
    {
      path: '/all-stat',
      name: 'allStatistics',
      meta: {
        keepAlive: true,
        nullLayout: true
      },
      component: () => import('../views/statistics/index.vue')
    },
    {
      path: '/charts',
      name: 'charts',
      meta: {
        keepAlive: true,
        nullLayout: true
      },
      component: () => import('../views/statistics/dgCharts.vue')
    },
    // 项目统计
    {
      path: '/project/statistics',
      name: 'statistics',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/project/project-statistics.vue')
    },
    // 测试项目统计
    {
      path: '/project/testTable',
      name: 'testTable',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/testTable/index.vue')
    },
    // 新企业控制台（珠海大屏版）
    {
      path: '/project/newConsole',
      name: 'newConsole',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/newConsole/index.vue')
    },
    // 新企业控制台
    {
      path: '/project/console',
      name: 'myConsole',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/myConsole/index.vue')
    },
    // 地图总览
    {
      path: '/project/overView',
      name: 'overView',
      meta: {
        keepAlive: true,
        nullLayout: true
      },
      component: () => import('../views/overView/index.vue')
    },
    // 游戏统计页面
    {
      path: '/gameStatistics',
      name: 'gameStatistics',
      meta: {
        keepAlive: false,
        nullLayout: true
      },
      component: () => import('../views/gameStatistics/index.vue')
    },
    // 埋点统计页面
    {
      path: '/myStatistics',
      name: 'myStatistics',
      meta: {
        keepAlive: false,
        nullLayout: true
      },
      component: () => import('../views/myStatistics/index.vue')
    },
    // 实施工具后台
    {
      path: '/implementationTools',
      name: 'implementationTools',
      meta: {
        keepAlive: false,
        nullLayout: true
      },
      component: () => import('../views/superAdmin/console.vue')
    },
    // 超级管理员页面
    {
      path: '/superAdmin',
      name: 'superAdmin',
      meta: {
        keepAlive: false,
        nullLayout: true
      },
      component: () => import('../views/superAdmin/index.vue')
    },
    // 创建订单
    {
      path: '/createOrder',
      name: 'createOrder',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/order/createOrder.vue')
    },
    // 创建订单
    {
      path: '/confirmOrder',
      name: 'confirmOrder',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/order/confirmOrder.vue')
    },
    //未完成订单
    {
      path: '/unfinishedOrder',
      name: 'unfinishedOrder',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/order/unfinishedOrder.vue')
    },
    //成功支付
    {
      path: '/successOrder',
      name: 'successOrder',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/order/successOrder.vue')
    },
    //支付失败
    {
      path: '/payFail',
      name: 'payFail',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/order/payFail.vue')
    },
    // 账户设置
    {
      path: '/setting',
      name: 'setting',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/setting/setting.vue')
    },
    // 卖家上传
    {
      path: '/seller/video/upload',
      name: 'upload',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/seller/module-video-upload/upload.vue')
    },
    // 卖家发布上传
    {
      path: '/seller/video/release',
      name: 'releaseUpload',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/seller/module-video-upload/release-upload.vue')
    },
    // 卖家管理上传视频
    {
      path: '/seller/video/manage',
      name: 'videoManage',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/seller/module-video-manage/video-manage.vue')
    },
    // 404
    {
      path: '/intro',
      name: 'intro',
      meta: {
        keepAlive: false,
        title: '了解抖工',
      },
      component: () => import('../views/intro/index.vue')
    },
    //关于我们
    {
      path: '/about',
      name: 'about',
      meta: {
        keepAlive: false,
        title: '关于我们',
        newPage: true
      },
      component: () => import('../views/about/index.vue')
    },
    //视频中心
    {
      path: '/video',
      name: 'video',
      meta: {
        keepAlive: false,
        title: '视频中心',
        newPage: true
      },
      component: () => import('../views/video/index.vue')
    },
    // 404
    {
      path: '/errors',
      name: 'errors',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/errors/404.vue')
    },
    // 反馈
    {
      path: '/feedback',
      name: 'feedback',
      meta: {
        keepAlive: true
      },
      component: () => import('../views/feedback/feedback.vue')
    },
    // 企业介绍首页
    {
      path: '/introduction',
      name: 'introduction',
      meta: {
        keepAlive: false
      },
      component: () => import('../views/introduction/introduction.vue')
    },
    // 个人试卷打印
    {
      path: '/testPaperPrint',
      name: 'testPaperPrint',
      meta: {
        newPage: true
      },
      component: () => import('../views/testPaper/testPaperPrint.vue')
    },
    // 个人二维码
    {
      path: '/myQRCode',
      name: 'myQRCode',
      meta: {
        newPage: true
      },
      component: () => import('../views/myQRCode/index.vue')
    },
    {
      path: "*",
      redirect: "/errors"
    }
  ]
});