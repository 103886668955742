// 导出页面为PDF格式
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import copyrightCom from "./copyrightCom";
export default{
  install (Vue, options) {
    Vue.prototype.getPdf = function (qData) {

      // 微信判断
      
      var ua = navigator.userAgent.toLowerCase();
      if(ua.match(/MicroMessenger/i)=="micromessenger") {
        console.log("weixin")
        document.getElementById('tobrowser').style.display = 'block';
        return false
      } else if (ua.match(/QQ/i) == "qq") {
        console.log("QQ")
        document.getElementById('tobrowser').style.display = 'block';
        return false
      }else{
        console.log('browser')
      }

      var title = 'this.htmlTitle'
      document.documentElement.scrollTop = document.body.scrollTop = 0
      document.getElementById('pdfDom').style.display = 'block';
      document.getElementById('pdfDom').style.width = 210 + 'mm';
      document.getElementById('pdfDom').style.padding = '20mm 19mm';
      
      //添加页脚
      let ql = qData.data.length

      // 计算总长度 拿到多少页
      // 规则 按每题的高度 超出1123的整倍数就添加空行
      // 先获取头部info的高度
      // let infoHeight = document.getElementById('paperUserInfo').offsetHeight
      // let infoToTop = document.getElementById('paperUserInfo').offsetTop
      // console.log(infoHeight,infoToTop)
      let toTopHeight = 1100
      let toTopCount = 1
      for(let o = 1;o <= ql;o++){
        let toTop = document.getElementById('qlist' + (o - 1)).offsetTop + document.getElementById('qlist' + (o - 1)).offsetHeight
        let inPageNum = parseInt(toTop / 1123) + 1
        if(toTop - toTopHeight > 0){
          toTopHeight = toTopHeight + 1123
          toTopCount = toTopCount + 1
          document.getElementById('qlist' + (o - 1)).style.marginTop = 200 + "px"
        }
      }

      
      // 换页版权信息
      // let paperHeight = document.getElementById('pdfDom').clientHeight
      // let paperSize = Math.round(paperHeight / 1123) - 1
      // if(paperHeight > 1123){
      //   for(let j = 0;j<paperSize;j++){
      //     // 版权信息
      //     var copyDiv = document.createElement('div')
      //     copyDiv.innerHTML = '<span>佛山抖工科技有限公司出品</span><span>版权所有，违者必究</span>';
      //     copyDiv.style.position = 'absolute'
      //     copyDiv.style.width = 100 + '%'
      //     copyDiv.style.display = 'flex'
      //     copyDiv.style.left = 0
      //     copyDiv.style.padding = '0 19mm'
      //     copyDiv.style.justifyContent = 'space-between'
      //     copyDiv.style.top = ((j * 1123) + 1090) + 'px'
      //     document.getElementById("pdfDom").appendChild(copyDiv);

      //     //二维码
      //     var codeDiv = document.createElement('div')
      //     codeDiv.style.position = 'absolute'
      //     codeDiv.style.width = 100 + 'px'
      //     codeDiv.style.height = 100 + 'px'
      //     codeDiv.style.right = 30 + '%'
      //     codeDiv.innerHTML = `<img style="height:auto;width:100%;" src="${qData.qrcode}" alt />`;
      //     codeDiv.style.top = ((j * 1123) + 1040) + 'px'
      //     console.log(codeDiv)
      //     document.getElementById("pdfDom").appendChild(codeDiv);
      //   }
      // }
      // 换页版权信息end



      // console.log(paperHeight, parseInt(paperHeight / 1123))
      // if(ql<7){
      //   document.getElementById('qlist' + (ql - 1)).append(copy)
      // }
      // if(ql>=7){
      //   document.getElementById('qlist6').append(copy)
      //   for(let j = 7;j<ql;j+10){
      //     document.getElementById('qlist' + (j - 1)).append(copy)
      //   }
      // }

      let copyDom = document.getElementById('pdfDom').cloneNode(true);
      // console.log("!!!!",document.getElementById('pdfDom').style.width);
      // console.log(document.getElementById('pdfDom'))
    //   html2Canvas(document.querySelector('#pdfDom'), {
    //     allowTaint: true
    //   }).then(function (canvas) {
    //     let contentWidth = canvas.width
    //     let contentHeight = canvas.height
    //     let pageHeight = contentWidth / 592.28 * 841.89
    //     let leftHeight = contentHeight
    //     let position = 0
    //     let imgWidth = 595.28
    //     let imgHeight = 592.28 / contentWidth * contentHeight
    //     let pageData = canvas.toDataURL('image/jpeg', 1.0)
    //     let PDF = new JsPDF('', 'pt', 'a4')
    //     if (leftHeight < pageHeight) {
    //       PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
    //     } else {
    //       while (leftHeight > 0) {
    //         PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
    //         leftHeight -= pageHeight
    //         position -= 841.89
    //         if (leftHeight > 0) {
    //           PDF.addPage()
    //         }
    //       }
    //     }
    //     PDF.save(title + '.pdf')
    //   }
    //   )
    var getPixelRatio = function(context) {     // 获取设备的PixelRatio
        var backingStore = context.backingStorePixelRatio ||
            context.webkitBackingStorePixelRatio ||
            context.mozBackingStorePixelRatio ||
            context.msBackingStorePixelRatio ||
            context.oBackingStorePixelRatio ||
            context.backingStorePixelRatio || 1;
        return (window.devicePixelRatio || 1) / backingStore;
    };
    var getImgBase64 = function(url){
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        // console.log('file 转 base64结果：' + reader.result)
        this.iconBase64 = reader.result
      }
      reader.onerror = function (error) {
        // console.log('Error: ', error)
      }
    }
    // console.log(getPixelRatio)
    var shareContent = document.getElementById("pdfDom"); 
    var width = shareContent.offsetWidth; 
    var height = shareContent.offsetHeight; 
    var canvas = document.createElement("canvas");
    var context = canvas.getContext('2d'); 
    // var scale = getPixelRatio(context);    //将canvas的容器扩大PixelRatio倍，再将画布缩放，将图像放大PixelRatio倍。
    // console.log(window.devicePixelRatio,context,scale)
    var scale = 1; //不可预估错误时暂用这个
    canvas.width = width * scale; 
    canvas.height = height * scale;
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
    context.scale(scale, scale);
    context.translate(0,0)

    var opts = {
        taintTest : false,
        useCORS : true,
        allowTaint : false, 
        scale: scale, 
        canvas: canvas,
        width: width, 
        height: height,
        dpi: window.devicePixelRatio,
        backgroundColor: null,
    };
    html2canvas(shareContent, opts).then(function (canvas) {
        // console.log("999",canvas)
        // context.mozImageSmoothingEnabled = false;
        // context.webkitImageSmoothingEnabled = false;
        // context.msImageSmoothingEnabled = false;
        // context.imageSmoothingEnabled = false;
        var dataUrl = canvas.toDataURL('image/jpeg', 1.0);
        var newImg = document.createElement("img");
        newImg.src =  dataUrl;
        newImg.width = width;
        newImg.height= height;
        // document.getElementById("showImg").append(newImg);
        let contentWidth = canvas.width
        let contentHeight = canvas.height
        let pageHeight = contentWidth / 592.28 * 841.89
        let leftHeight = contentHeight
        let position = 0
        let imgWidth = 595.28
        let imgHeight = 592.28 / contentWidth * contentHeight
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        let PDF = new JsPDF('', 'pt', 'a4')
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
        PDF.save(title + '.pdf')
        document.getElementById('pdfDom').style.width = 100 + '%';
        document.getElementById('pdfDom').style.display = 'none';
        // document.getElementById('pdfDom').style.maxwidth = 
      });
    }
  }
}
