<template>
  <div class="layout-container">
    <div class="img-bg">
      <img src="../assets/images/1280.jpg" alt="抖工" />
    </div>
    <!-- <div class="video-bg">
      <video muted="" data-autoplay="" loop="loop" autoplay="autoplay" src="//s3.pstatp.com/aweme/resource/web/static/image/index/tvc-v3_0b9db49.mp4" class="video-tvc" id="video-tvc" data-object-fit="">抱歉，您的浏览器不支持内嵌视频</video>
    </div> -->
    <div class="main-wrapper">
      <header class="header">
        <div
          class="logo-box"
          @click="handleLogoClick"
        >
          <img src="../assets/images/logo_1080.png" alt="抖工科技">
        </div>
        <div class="nav-box">
          <!-- <div class="nav-item" @click="handleAboutClick">关于抖工</div> -->
          <div class="nav-item" @click="buyerSubmit">企业登录</div>
          <div class="nav-item" @click="toAboutUs">关于我们</div>
          <div class="nav-item" @click="toVideo">视频中心</div>
          <div class="nav-item" @click="handleNavClick">开放平台</div>
          <!-- <div class="nav-item" @click="sellerSubmit">视频上传</div> -->
        </div>
      </header>
      <section class="app-main">
        <transition name="fade-transform" mode="out-in">
          <keep-alive>
            <router-view :key="key" />
          </keep-alive>
        </transition>
      </section>
      <footer class="footer">
        <div class="footer-box">
          <span class="protocol-item" @click="toProtocolPage(1)">注册协议</span>
          <span class="protocol-item" @click="toProtocolPage(2)">隐私协议</span>
          <span class="protocol-item" @click="toProtocolPage(3)">侵权投诉指引</span>
        </div>
        <div class="footer-info">
<!--          <div>粤ICP备20000709号 | Copyright  2019-2020 佛山抖工科技有限公司</div>-->
          <div><a target="_blank" href="http://beian.miit.gov.cn" title="抖工科技有限公司"> 粤ICP备20000709号 | Copyright  2019-2020 佛山抖工科技有限公司</a></div>
          <div><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060502001916" title="抖工科技有限公司"><i class="police"></i> 粤公网安备 44060502001916号</a></div>
          <div>地址：佛山市南海区桂城街道桂澜北路28号南海万达广场南1栋2509室</div>
          <div style="margin-top: 5px;color: #fff;font-size: 1rem;">服务热线：400-640-6898</div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  computed: {
    key() {
      return this.$route.path
    }
  },
  methods: {
    // 买家跳转
    buyerSubmit() {
      this.user = this.common.getStorage(this.CONSTANTS.USER_INFO);
      if (this.user) {
        if(this.user.maxRoleType=='ADMINISTRATOR'){
            this.$router.push({name: 'myConsole'});

        }else{
            this.$router.push({name: 'myProject'});

        }
      } else {
        // this.$router.push({name: 'registerEnterprise', params: {type: 1}});
        this.$router.push({ name: 'login' });
      }
    },
    handleAboutClick() {
      this.$router.push({name: 'intro'});
    },
    handleNavClick(name) {
      const URL = 'http://doc.dougongapp.com:18080/en/docs/'
      window.open(URL, '_blank')
    },
    // 跳转至关于我们
    toAboutUs(){
      this.$router.push({name: 'about'});
    },
    toVideo(){
      this.$router.push({name: 'video'});
    },
    // 卖家跳转
    sellerSubmit() {
      // this.$Message.error("暂未开放");
      this.user = this.common.getStorage(this.CONSTANTS.USER_INFO);
      if (this.user) {
        this.$router.push({name: 'upload'});
      } else {
        this.$router.push({name: 'login', params: {type: 2}})
      }
    },
    toProtocolPage(type) {
      this.common.toProtocolPage(type);
    },
    handleLogoClick() {
      this.$router.push({name: 'home', params: {type: 1}});
    }
  }
}
</script>

<style lang="stylus">
flex()
  display flex
  justify-content space-between
  align-items center
btn-icon()
  width 100%
  height 100%
  display block
  border-radius 5px
  background-position center
  background-repeat no-repeat
  background-size cover

.layout-container
  position relative
  width 100%
  min-width 1280px
  height 100vh
  .main-wrapper
    position relative
    width 1140px
    height 100%
    margin 0 auto
    color #ffffff
    z-index 1
  .img-bg
    width 100%
    height 100%
    position absolute
    img
      width 100%
      height 100%
  .video-bg
    width 100%
    height 100%
    position absolute
    .video-tvc
      height: 100%;
      width: 100%;
      object-fit: cover;
  .header
    flex()
    padding-top 1.875em
    position fixed
    left 0
    top 0
    width 100%
    padding-left 10%
    padding-right 10%
    background linear-gradient(to bottom, #232323ba 0%,rgba(0,0,0,0) 100%)
  .logo-box
    width 120px
    height auto
    img
      width 100%
  .nav-box
    flex()
    .nav-item
      font-size 16px
      font-weight 500
      width 100px
      color #ffffff
      cursor pointer
      transition all .5s
      text-align center
      &:hover
        font-size 16px
        transition all .5s
  .footer
    width 100%
    position absolute
    bottom 3em
    .footer-box
      flex()
      justify-content space-between
      margin 0 auto
      width 40%
      min-width 28em
      max-width 30em
      .protocol-item
        color #ffffff
        font-size 1.3rem
        cursor pointer
    .footer-info
      margin-top 5px
      text-align center
      font-size 12px
      color #999
      i
        display inline-block;
        vertical-align middle;
        margin-top -3px
        margin-left 5px
        width 18px
        height 18px
        background-repeat no-repeat
        background-size contain
        cursor pointer
      .police
        background-image url('../assets/images/guohui.png')
      a
        color #999

@import '../assets/styles/login'
</style>
