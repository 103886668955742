<template>
  <div id="app">
    <dashboard v-if="$route.meta.keepAlive && !$route.meta.nullLayout"></dashboard>
    <router-view v-else-if="$route.meta.newPage" />
    <div v-else>
      <div class="no-layout-container" v-if="$route.meta.nullLayout">
        <!-- 无登陆状态并无需层嵌套 -->
        <router-view />
      </div>
      <layout-login v-else></layout-login>
    </div>
  </div>
</template>

<script>
import Login from  '@layouts/Login'
import Dashboard from '@layouts/Dashboard'

export default {
  name: 'App',
  components: {
    Dashboard,
    LayoutLogin: Login
  }
}
</script>

<style lang="less">
.amap-sug-result { z-index: 99999 !important; }
*{
  font-family: "Helvetica Neue",Helvetica,Arial,"Microsoft Yahei","Hiragino Sans GB","HeitiSC","WenQuanYi Micro Hei",sans-serif;
}
</style>