
const IS_PROD = ["production", "prod"].includes(process.env.NODE_ENV);

const EOVA_AUTH = "EOVA_Authorization";
const AUTH_EXPIRE = 60;
const USER_INFO = "user_info";
const LOGIN_SMS_TYPE = "1";
const REGISTER_SMS_TYPE = "2";
const FORGETPWD_SMS_TYPE = "3";
const FRAME_URL = IS_PROD ? "http://www.dougongapp.com:9988/frame/" : "http://localhost:8889/frame/";
const VIDEO_URL = IS_PROD ? "http://www.dougongapp.com:9988/video/" : "http://localhost:8889/video/";
const MOBILE_INDEX_URL = IS_PROD ? "http://www.dougongapp.com:8899" : "http://localhost:8888/";
const MOBILE_LOGIN_URL = IS_PROD ? "http://www.dougongapp.com:8899/#/user/login/normal" : "http://localhost:8888/#/user/login/normal"
// const FRAME_URL = "http://134.175.132.191:8088/frame/";
// const VIDEO_URL = "http://134.175.132.191:8088/video/";
// const FRAME_URL = "http://www.dougongapp.com:8889/frame/";
// const VIDEO_URL = "http://www.dougongapp.com:8889/video/";
// const MOBILE_INDEX_URL = "http://www.dougongapp.com:8899/";
// const MOBILE_LOGIN_URL = "http://www.dougongapp.com:8899/#/user/login/normal";
// const MOBILE_INDEX_URL = "http://134.175.132.191:8899/";
// const MOBILE_LOGIN_URL = "http://134.175.132.191:8899/#/user/login/normal";

export default {
  EOVA_AUTH,
  AUTH_EXPIRE,
  USER_INFO,
  LOGIN_SMS_TYPE,
  REGISTER_SMS_TYPE,
  FORGETPWD_SMS_TYPE,
  FRAME_URL,
  VIDEO_URL,
  MOBILE_INDEX_URL,
  MOBILE_LOGIN_URL,
}
